import axios from "axios";

export default {
  getStatementA() {
    const token = localStorage.getItem("token");
    return axios.get("/enStatementsChartA", {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },

  getStatementB() {
    const token = localStorage.getItem("token");
    return axios.get("/enStatementsChartB", {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },

  getStatementC() {
    const token = localStorage.getItem("token");
    return axios.get("/enStatementsChartC", {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },
  getStatementD() {
    const token = localStorage.getItem("token");
    return axios.get("/enStatementsChartD", {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },
  getStatementE() {
    const token = localStorage.getItem("token");
    return axios.get("/enStatementsChartE", {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },
  getStatementF() {
    const token = localStorage.getItem("token");
    return axios.get("/enStatementsChartF", {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },
  getStatementG() {
    const token = localStorage.getItem("token");
    return axios.get("/enStatementsChartG", {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },
  getStatementH() {
    const token = localStorage.getItem("token");
    return axios.get("/enStatementsChartH", {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },
  getStatementI() {
    const token = localStorage.getItem("token");
    return axios.get("/enStatementsChartI", {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },
  getStatementJ() {
    const token = localStorage.getItem("token");
    return axios.get("/enStatementsChartJ", {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },
  getStatementK() {
    const token = localStorage.getItem("token");
    return axios.get("/enStatementsChartK", {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },
  getStatementL() {
    const token = localStorage.getItem("token");
    return axios.get("/enStatementsChartL", {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },
  getStatementM() {
    const token = localStorage.getItem("token");
    return axios.get("/enStatementsChartM", {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },
  getStatementN() {
    const token = localStorage.getItem("token");
    return axios.get("/enStatementsChartN", {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },
  getStatementO() {
    const token = localStorage.getItem("token");
    return axios.get("/enStatementsChartO", {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        Accept: "Application/json",
        Authorization: `Bearer ` + token,
      },
    });
  },
};
